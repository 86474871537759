import React, { useEffect } from "react";
import SetUpPassword from "./SetUpPassword";

function Index() {
  useEffect(() => {
    const selectBody = document.querySelector("body");
    selectBody.setAttribute("id", "home-3");
  }, []);

  return (
    <>
      <SetUpPassword />
    </>
  );
}

export default Index;
