import React, { useState, useContext } from "react";
import { EmailContext } from "../Contexts/EmailContext";
import { CodeContext } from "../Contexts/CodeContext";
import { useNavigate } from "react-router-dom";
import "./verificationCode.css";

function EmailVerificationCode() {
  const [enteredCode, setEnteredCode] = useState(0);
  const { currentEmail } = useContext(EmailContext);
  const { currentCode } = useContext(CodeContext);
  const navigate = useNavigate();

  console.log(currentEmail, currentCode, enteredCode);

  const handleEnterCode = () => {
    if (enteredCode == currentCode) {
      navigate("/set-password");
    } else {
      alert(
        "The entered code does not match the verification code sent to your email. Please re-enter your code. To get a new code, click 'resend code'."
      );
    }
  };

  const handleResendCode = () => {
    navigate("/email-verification");
  };

  return (
    <section className="pricing-section-s1 s-padding">
      <div className="container">
        <div className="homeButton">
          <a href="/" className="btn btn-s6">
            Home
          </a>
        </div>
        <div className="s-title-wrap">
          <h2 className="s-title">Verification Code</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-7 col-lg-6 col-xl-5">
            <div className="pricing-t-s1 bx-1">
              <div className="pt-header">
                <div className="icon"></div>
                <h4 className="text">
                  An email verification code has been sent to the email address
                  you provided. Please open the email and enter the code you
                  recieved.
                </h4>
                <div>
                  <p className="inputLabel">Code</p>
                  <input
                    type="text"
                    id="code"
                    name="code"
                    placeholder="enter code"
                    className="inputField"
                    onChange={(event) => setEnteredCode(event.target.value)}
                  ></input>
                </div>
                <button
                  className="btn btn-s6"
                  onClick={() => handleEnterCode()}
                >
                  Submit Code
                </button>
              </div>
            </div>
          </div>
        </div>

        <button className="resendCodeButton" onClick={handleResendCode}>
          resend code
        </button>
      </div>
    </section>
  );
}

export default EmailVerificationCode;
