import React, { useState, useContext } from "react";
import { EmailContext } from "../Contexts/EmailContext";
import Dropdown from "react-bootstrap/Dropdown";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase/firebase-config";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import "./setUpPassword.css";

function SetUpPassword() {
  const { currentEmail } = useContext(EmailContext);
  const [enteredPassword, setEnteredPassword] = useState("");
  const [accountType, setAccountType] = useState("Select Account Type");

  console.log(accountType);

  const navigate = useNavigate();
  const handleRegistration = async () => {
    console.log("handle registration");
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        currentEmail,
        enteredPassword
      );
    } catch (error) {
      console.log(error);
      const errorCode = error.code;
      const errorMessage = error.message;
      switch (errorCode) {
        case "auth/invalid-email":
          alert(
            "You entered an invalid email address. Please click the 'Home' button, then click the 'Sign Up' button, and then enter a valid email address."
          );
          break;
        case "auth/email-already-in-use":
          alert(
            "The email address you entered is already in use. Please open the fillin mobile app and sign in. Or, if you wish to sign up with a different email, click the 'Home' button and then click the 'Sign Up' button."
          );
          break;
        case "auth/weak-password":
          alert(
            "Weak password. Your password must have at least 6 characters."
          );
          break;
        default:
      }
    }
    let accountDB = "";
    if (accountType === "business") {
      accountDB = "fillinBusinesses";
      await setDoc(
        doc(db, "users", currentEmail),
        {
          accountDB: accountDB,
          email: currentEmail,
          userId: auth.currentUser.uid,
        },
        { merge: true }
      );
    } else {
      accountDB = "fillins";
      await setDoc(
        doc(db, "users", currentEmail),
        {
          accountDB: accountDB,
          email: currentEmail,
          userId: auth.currentUser.uid,
        },
        { merge: true }
      );
    }
    navigate("/QR-code");
  };
  return (
    <section className="pricing-section-s1 s-padding">
      <div className="container">
        <div className="homeButton">
          <a href="/" className="btn btn-s6">
            Home
          </a>
        </div>
        <div className="s-title-wrap">
          <h2 className="s-title">Register</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-7 col-lg-6 col-xl-5">
            <div className="pricing-t-s1 bx-1">
              <div className="pt-header">
                <div className="icon"></div>
                <p className="text">
                  Your email address has been verified. To complete your
                  registration, select an account type and then enter a
                  password.
                </p>

                <p className="text">
                  Please select the account type that fits your needs. If you
                  are business with shifts to fill, select "business". If you
                  are looking for work, select "individual".
                </p>
                <p className="inputLabel">Account Type</p>
                <div className="dropdownMenu">
                  <Dropdown>
                    <Dropdown.Toggle variant="custom" id="dropdown-basic">
                      {accountType}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setAccountType("business")}>
                        business
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setAccountType("individual")}
                      >
                        individual
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <p className="inputLabel">Password</p>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="enter password"
                    className="inputField"
                    onChange={(event) => setEnteredPassword(event.target.value)}
                  ></input>
                </div>
                <button
                  className="btn btn-s6"
                  onClick={() => handleRegistration()}
                >
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SetUpPassword;
//update
