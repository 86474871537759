import React, { useEffect } from "react";
import EmailVerificationCode from "./EmailVerificationCode";

function Index() {
  useEffect(() => {
    const selectBody = document.querySelector("body");
    selectBody.setAttribute("id", "home-3");
  }, []);

  return (
    <>
      <EmailVerificationCode />
    </>
  );
}

export default Index;
