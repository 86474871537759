import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { EmailContext } from "../Contexts/EmailContext";
import { CodeContext } from "../Contexts/CodeContext";
import "./emailVerification.css";

function EmailVerification() {
  const [enteredEmail, setEmail] = useState("");
  let email = enteredEmail.toLowerCase();
  console.log(email);
  const [code, setCode] = useState(0);

  const { setCurrentEmail } = useContext(EmailContext);
  const { setCurrentCode } = useContext(CodeContext);
  const navigate = useNavigate();

  useEffect(() => {
    setCode(Math.floor(Math.random() * 10000));
  }, []);

  function handleVerifyEmail() {
    let templateParams = {
      code: code,
      email: email,
    };
    console.log(code);
    emailjs
      .send(
        "service_mvo0naj",
        "template_0grilgs",
        templateParams,
        "fplCzqUpp5xGniu9X"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setCurrentEmail(email);
    setCurrentCode(code);
    navigate("/verification-code");
  }

  return (
    <section className="pricing-section-s1 s-padding">
      <div className="container">
        <div className="s-title-wrap">
          <div className="homeButton">
            <a href="/" className="btn btn-s6">
              Home
            </a>
          </div>

          <h2 className="s-title">Email Verification</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-7 col-lg-6 col-xl-5">
            <div className="pricing-t-s1 bx-1">
              <div className="pt-header">
                <div className="icon"></div>
                <h2>Please verify your email addreses</h2>
                <div>
                  <div className="inputLabel">
                    <p>Email</p>
                  </div>
                  <input
                    className="inputField"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="enter email"
                    onChange={(event) => setEmail(event.target.value)}
                  ></input>
                </div>
                <button className="btn btn-s6" onClick={handleVerifyEmail}>
                  Verify Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EmailVerification;
