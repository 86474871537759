import React, { useState } from "react";
import { EmailContext } from "./components/Contexts/EmailContext";
import { CodeContext } from "./components/Contexts/CodeContext";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Routers from "./Routers";

function App() {
  const [currentEmail, setCurrentEmail] = useState("default");
  const [currentCode, setCurrentCode] = useState("default");
  return (
    <EmailContext.Provider value={{ currentEmail, setCurrentEmail }}>
      <CodeContext.Provider value={{ currentCode, setCurrentCode }}>
        <Routers />;
      </CodeContext.Provider>
    </EmailContext.Provider>
  );
}

export default App;
