import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FillinHome from "./components/FillinHome";
import FillinEmailVerification from "./components/FillinEmailVerification";
import FillinEmailVerificationCode from "./components/FillinEmailVerificationCode";
import FillinRegistration from "./components/FillinRegistration";
import FillinQRCode from "./components/FillinQRCode";
import DeleteAccount from "./components/DeleteAccount";

function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<FillinHome />} />
      <Route
        exact
        path="/email-verification"
        element={<FillinEmailVerification />}
      />
      <Route
        exact
        path="/verification-code"
        element={<FillinEmailVerificationCode />}
      />
      <Route exact path="/set-password" element={<FillinRegistration />} />
      <Route exact path="/QR-code" element={<FillinQRCode />} />
      <Route exact path="/deleteAccount" element={<DeleteAccount />} />
    </Routes>
  );
}

export default Routers;
