import React, { useEffect } from "react";
// import Header from "./Header";
import Hero2 from "./Hero2";
import FeatureSectionSlider2 from "./FeatureSectionSlider2";
// import TestimonialSection from "./TestimonialSection";
// import PricingSection from "./PricingSection";
import Connect2 from "./Connect2";
// import Footer from "./Footer";

function Index() {
  useEffect(() => {
    const selectBody = document.querySelector("body");
    selectBody.setAttribute("id", "home-3");
  }, []);

  return (
    <>
      {/* <Header /> */}
      <Hero2 />
      <FeatureSectionSlider2 />
      {/* <TestimonialSection /> */}
      {/* <PricingSection /> */}
      <Connect2 />
      {/* <Footer /> */}
    </>
  );
}

export default Index;

// test change
