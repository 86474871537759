import React from "react";
import "./FillinHome.css";

function Hero2() {
  return (
    <section className="hero-s3">
      <div className="stretch-container">
        <div className="row flex-lg-col-reverse">
          <div className="col-lg-6 hero-s2__content">
            <h2 className="content-h">Welcome to the fillin Gig Network</h2>
            <h1 className="content-sh">
              We connect people looking for work (fillins) with our app users
              that have gigs to offer. If you have a gig to fill or are looking
              for a gig, we are eager to help. Scan the QR code below to get
              started.
            </h1>
            {/* <a href="/email-verification" className="btn btn-s6">
              Sign Up
            </a> */}
            <div className="fillinQRcodeBox">
              <img
                id="QRcode"
                className="fillinQRcode"
                src={require(`../../assets/images/QRCodes/fillin_QR_code.png`)}
                alt="fillin QR code"
              />
            </div>
          </div>
          <div className="col-lg-6 hero-s2__thumb">
            <div className="img">
              <img
                id="logo"
                className="fillinLogo"
                src={require(`../../assets/images/hero-img/fillin_logo_640X572.png`)}
                alt="fillin logo"
              />
              <div className="shapes">
                <img
                  id="spin2"
                  src={require(`../../assets/images/shapes/hero-2-thumb-shape-2.png`)}
                  alt=""
                  className="shp-2 spin"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero2;
