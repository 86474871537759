import React from "react";

import "./QRCode.css";

function EmailVerificationCode() {
  return (
    <section className="pricing-section-s1 s-padding">
      <div className="container">
        <div className="homeButton">
          <a href="/" className="btn btn-s6">
            Home
          </a>
        </div>
        <div className="s-title-wrap">
          <h2 className="s-title">Welcome</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-7 col-lg-6 col-xl-5">
            <div className="pricing-t-s1 bx-1">
              <div className="pt-header">
                <div className="icon"></div>
                <p>
                  Welcome to the fillin gig network. We are eager to help you
                  get started.{" "}
                </p>
                <p>
                  To build your profile, scan our QR code with your mobile
                  device, download the fillin app, and log in with your email
                  and password.{" "}
                </p>
                <p>
                  Or download the app from the App Store (search: fillin - work
                  made easy) or Google Play (search: fillin).
                </p>

                <p>
                  If you have questions or comments, email us at
                  success@fillin.ninja.
                </p>
                <img
                  src={require(`../../assets/images/QRCodes/fillin_QR_code.png`)}
                  alt="fillin logo"
                  className="d-block"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EmailVerificationCode;
