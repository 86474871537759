import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";

//https://stackoverflow.com/questions/37482366/is-it-safe-to-expose-firebase-apikey-to-the-public
//https://firebase.google.com/docs/projects/api-keys
const firebaseConfig = {
  apiKey: "AIzaSyAY67SS1JzyhoSR369JqpK0vUpTsK3cak8",
  authDomain: "fillin-mobile-app-8807b.firebaseapp.com",
  projectId: "fillin-mobile-app-8807b",
  storageBucket: "fillin-mobile-app-8807b.appspot.com",
  messagingSenderId: "540678517166",
  appId: "1:540678517166:web:8c7e041713e2ebfd80d83f",
  measurementId: "G-Y9E81H729D",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const getDocument = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log(
        `Document does not exist for collection ${collectionName} and id ${documentId}`
      );
      return null;
    }
  } catch (error) {
    console.error(
      `Error getting document for collection ${collectionName} and id ${documentId}`,
      error
    );
    return null;
  }
};

export { auth, db, storage, firebaseConfig, getDocument };
