import React from "react";
import SliderCom from "../helpers/SliderCom";

import "./FillinHome.css";

function FeatureSectionSlider2() {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    prevArrow: "",
    nextArrow: "",
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="features-slider-s3 s-padding">
      <div className="container">
        <div className="s-title-wrap">
          <h2 className="s-title">Here's how fillin works ...</h2>
        </div>
        <div className="row feature-s3-inner">
          <SliderCom settings={settings}>
            <div className="col-xl-4">
              <div className="feature-s5">
                <div className="thumb">
                  <h1 className="steps">1</h1>
                </div>
                <div className="content">
                  <p className="sliderText">
                    <a href="/service-detail">
                      Scan the QR code and download the app.
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="feature-s5">
                <div className="thumb">
                  <h1 className="steps">2</h1>
                </div>
                <div className="content">
                  <p className="sliderText">
                    <a href="/service-detail">
                      Post a gig or a gig you'd like to fill.
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="feature-s5">
                <h1 className="steps">3</h1>
                <div className="content">
                  <p className="sliderText">
                    <a href="/service-detail">
                      Connect with users to fill a gig.
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="feature-s5">
                <div className="thumb">
                  <h1 className="steps">4</h1>
                </div>
                <div className="content">
                  <p className="sliderText">
                    <a href="/service-detail">
                      Chat online to work out the details.
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="feature-s5">
                <div className="thumb">
                  <h1 className="steps">5</h1>
                </div>
                <div className="content">
                  <p className="sliderText">
                    <a href="/service-detail">
                      With a few clicks, you are ready to go.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </SliderCom>
        </div>
      </div>
    </section>
  );
}

export default FeatureSectionSlider2;
