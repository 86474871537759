import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import "./FillinHome.css";

function Connect2() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState(0);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  console.log(name);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload(false);
  };
  // const handleShow = () => setShow(true);

  useEffect(() => {}, [name]);

  function handleEmail() {
    let templateParams = {
      name: name,
      phone: phone,
      email: email,
      subject: subject,
      message: message,
    };
    emailjs
      .send(
        "service_mvo0naj",
        "template_hr3k3nl",
        templateParams,
        "fplCzqUpp5xGniu9X"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setShow(true);
  }

  return (
    <section className="contact-form-area s-padding">
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body>
          <p className="modalText">
            Thank you for connecting. We will respond to your query in two to
            three business days.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="contact-info">
              <span className="s-sub-title">Connect With Us</span>
              <h2 className="s-title">Feel Free to Get in Touch</h2>
              <div className="c-info-box-wrap">
                {/* <Link to="mailto:success@fillin.ninja" className="c-info-box">
                  <div className="icon">
                    <img
                      src={require(`../../assets/images/feature-icons/evelope.png`)}
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <span>Email</span>
                    <h4>success@fillin.ninja</h4>
                  </div>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="contact-form-s1">
              <form action="#">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name*"
                  required
                  onChange={(event) => setName(event.target.value)}
                />
                <input
                  type="number"
                  name="number"
                  placeholder="Phone Number*"
                  required
                  onChange={(event) => setPhone(event.target.value)}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email*"
                  required
                  onChange={(event) => setEmail(event.target.value)}
                />
                <input
                  type="text"
                  name="subject"
                  placeholder="Your Subject*"
                  required
                  onChange={(event) => setSubject(event.target.value)}
                />
                <textarea
                  name="message"
                  cols="30"
                  rows="10"
                  placeholder="Message here"
                  onChange={(event) => setMessage(event.target.value)}
                ></textarea>
              </form>
              <div className="d-flex align-items-center justify-content-center text-center">
                <button
                  className="btn btn-s6 submitButton"
                  onClick={() => handleEmail()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Connect2;
