import React from "react";
import "./DeleteAccountCSS.css";
// import Header from "./Header";

function DeleteAccount() {
  return (
    <div className="container">
      <h1 className="title">Delete Your Account</h1>
      <p>Thank you for trying fillin. We are sorry to see you go.</p>
      <p>To delete your account:</p>
      <p>1. Open the fillin app</p>
      <p>2. Log in</p>
      <p>3. Click the profile tab</p>
      <p>4. Click the "Delete Your Account" link</p>
      <p>5. Click the Delete Account button</p>
      <p>
        Your account and all it's data will be deleted within two business days.
      </p>
      <p>
        If you have comments or questions, we'd like to hear from you. Our email
        address is success@fillin.ninja.
      </p>
    </div>
  );
}

export default DeleteAccount;
